import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {Redirect} from 'react-router-dom';
import SecureLS from 'secure-ls';
import { getUserInfo } from 'store/effects';
import { loadGlobalDataEffect } from 'store/effects/GlobalDataEffects';

const ls = new SecureLS({ encodingType: 'aes' });


function AuthGuard(props: any) {
  const { children } = props;
  const dispatch = useDispatch();

  let token: any;
  try {
    token = ls.get('token');
  } catch (error) {}
  if (!token) {
    return <Redirect to="/auth/login" />;
  }

  const type_window = typeof window;

  useEffect(() => {
    console.log("getUserInfo::loadGlobalDataEffect");
    dispatch(getUserInfo());
    dispatch(loadGlobalDataEffect());
  }, [type_window]);
  return children;
}

export default AuthGuard;

