export default {
  translation: {
    'shared.french': 'Francais',
    'shared.english': 'Anglais',
    'loading_map_text': 'Chargement de la carte',
    "field_require": "Champ requis",
    "login_page_action": "Se connecter",
    "login_page_title": "Connexion",
    "login_form_email": "Email",
    'shared.internet_connexion_error': ' Une erreur inconnue s\'est produite. Veuillez ressayer plus tard',
    "login_form_password": "Mot de passe",
    "login_form_require": "Champ requis",
    "login_field_code": "Code",
    "login_step_code_description": "Entrez le code reçu par e-mail pour vous connecter",
    "login_step_email": "Connectez vous en renseignant votre email",
    "app_name": "Tele CALL",
    error_message_page: "Page Introuvable",
    cause_error_not_found: "Cette page est en cours de construction  ou n'existe pas",
    go_back_to_home: "Retour à l'accueil",
    topbar_profile_logout: 'Déconnexion',
    topbar_profile_profil: 'Profil',
    navigation_items_user: "Utilisateurs",
    "file_dropzone_placeholder": "Cliquer ou Glisser un fichier",
    "form_action_save": "Enregistrer",
    "form_action_edit": "Modifier",
    "form_action_delete": "Supprimer",
    "form_action_confirm": "Confirmer",
    "form_action_close": "Fermer",
    "form_action_add": "Ajouter",
    "form_action_cancel": "Annuler",
    "dialog_action_confirm": "Confirmer",
    "dialog_action_cancel": "Annuler",
    "profile_page_": "",
    "profile_page_user_avatar": "Photo de profil",
    "profile_page_user_avatar_edit_title": "Modifier la photo de profil",
    "profile_page_user_mission_type": "Type de missions",
    "profile_page_user_mission_type_edit": "Modifier les types de missions",
    "profile_page_user_mission_type_edit_success": "Types de missions mis à jour avec succès",
    "profile_page_user_activity_sectors": "Secteurs d'activité",
    "profile_page_user_activity_sectors_edit": "Modifier les secteurs d’activités",
    "profile_page_user_activity_sectors_edit_success": "Secteurs d’activité mis à jour avec succès",
    "profile_page_user_info": "Données personnelles",
    "profile_page_user_info_edit_success": "Données personnelles mis à jour avec succès",
    "profile_page_user_add_title": "Nouvel utilisateur",
    "user_page_add_user": "Ajouter un utilisateur",
    "user_page_add_user_success": "Un nouvel utilisateur a été ajouté",
    "user_page_delete_user_success": "L'utilisateur a été supprimé",
    "profile_page_user_info_civility": "Civilité",
    "profile_page_user_info_role": "Rôle",
    "profile_page_user_info_status": "Statut",
    "profile_page_user_info_fullName": "Nom(s)",
    "profile_page_user_info_firstname": "Nom",
    "profile_page_user_info_lastname": "Prénom",
    "profile_page_user_info_birthday": "Date de naissance",
    "profile_page_user_info_email": "Adresse mail",
    "profile_page_user_info_user_name": "Nom d'utilisateur",
    "profile_page_user_info_password": "Mot de passe",
    "profile_page_user_info_my_account": "Mon compte",
    "profile_page_user_info_connexion": "Connexion",
    "profile_page_user_info_edit_title": "Modifier les informations utilisateurs",
    "profile_page_user_bank_account": "Données bancaires",
    "profile_page_user_bank_account_edit_success": "Données bancaires mis à jour avec succès",
    "profile_page_user_bank_account_edit_title": "Modifier les informations bancaires",
    "profile_page_user_bank_account_iban": "IBAN",
    "profile_page_user_bank_account_paypal": "Paypal",
    "profile_page_user_bank_account_paypal_email": "Email paypal",
    "profile_page_user_bank_account_bank_transfert": "Virement bancaire",
    "profile_page_user_description": "Texte de présentation",
    "profile_page_user_description_edit": "Modifier les informations utilisateurs",
    "profile_page_user_availability": "Disponibilités",
    "profile_page_user_availability_edit": "Modifier la disponibilité",
    "users_page_user_delete_confirm_title": "Confirmation de suppression",
    "users_page_user_delete_confirm_message": "Voulez-vous vraiment supprimer cet utilisateur?",
    "monday": "Lundi",
    "tuesday": "Mardi",
    "wednesday": "Mercredi",
    "thursday": "Jeudi",
    "friday": "Vendredi",
    "saturday": "Samedi",
    "sunday": "Dimanche",
    "table_head_action": "Actions",
    "page_notifications_read_all": "Tout marquer comme lu",
  }
};
