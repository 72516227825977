import {ThunkAction} from 'redux-thunk';
import {ApplicationAction, ApplicationState} from 'store/types';
import {loginSuccess, updateLoginLoading} from 'store/actions';
import {AuthService} from 'services/api';
import {loginError, loginRequest} from 'store/actions/AccountActions';

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;

export const getUserInfo = (): Effect => async (dispatch, getState) => {
  const account = getState().account
  return AuthService.get_userInfo()
    .then(async (res: any) => {
      if (res.status === 200) {
        let {user} = await res.json();
        return dispatch(loginSuccess({...account.user, ...user}, account.token || ''));
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
};


export const loginEffect = (
  email: string,
  code: string,
  onError: (msg: string) => void,
  onSuccess?: () => void,
): Effect => async (dispatch, getState) => {
  const {
    i18n: {t = (key: string) => key}
  } = getState();
  dispatch(loginRequest());
  return AuthService.endLogin({email, code})
    .then(async (res: any) => {
      if (res.status === 200 || res.status === 201) {
        let {
          user,
          token
        } = await res.json();
        dispatch(loginSuccess(user, token.access_token));
        if (onSuccess) {
          onSuccess();
        }
      } else {
        let {message, detail} = await res.json();
        onError(message || detail);
        dispatch(loginError(message || detail));
      }
    })
    .catch((err: any) => {
      onError(t('shared.internet_connexion_error'))
      return dispatch(loginError(t('shared.internet_connexion_error')));
    })
};


export const startLoginEffect = (
  email: string,
  setLoading: Function,
  onError: (msg: string) => void,
  onSuccess: (msg: string) => void,
): Effect => async (dispatch, getState) => {
  const {
    i18n: {t = (key: string) => key}
  } = getState();
  setLoading(true)
  return AuthService.startLogin(email)
    .then(async (res: any) => {
      if (res.status === 200 || res.status === 201) {
        const {message} = await res.json();
        onSuccess(message);
        setLoading(false)
      } else {
        let {message, detail} = await res.json();
        onError(message || detail);
        setLoading(false)
      }
    })
    .catch((err: any) => {
      onError(t('shared.internet_connexion_error'));
      setLoading(false)
    })
};

