import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import {DatePickerView,} from '@mui/lab/DatePicker/shared';
import moment from "moment";
import 'moment/locale/fr';
import AdapterDateFns from '@mui/lab/AdapterMoment';
import {useTranslation} from 'react-i18next';
import {TextField, InputAdornment, Box, useMediaQuery, useTheme, Theme} from '@mui/material';
import {SvgIconProps} from "@mui/material/SvgIcon";
import {StandardTextFieldProps} from "@mui/material";
import {DatePicker, LocalizationProvider, TimePicker, DateTimePicker} from "@mui/lab";
import {Calendar, Clock} from "react-feather";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(2),
        "& svg": {
            fill: '#fff'
        }
    },
}));

export interface InputTextProps extends StandardTextFieldProps {
    labelPrefix?: string;
    labelSuffix?: string;
    clearContent?: () => void;
    startIcon?: React.ReactElement<SvgIconProps>;
    endIcon?: React.ReactElement<SvgIconProps>;
    onInputOverflowClick?: () => void;
}

export interface InputDateComponentProps extends InputTextProps {
    views?: DatePickerView[];
    timeViews?: ('hours' | 'minutes' | 'seconds')[];
    dateMomentFormat: string;
    minDate?: Date;
    maxDate?: Date;
    format?: string;
    onDateChange: (date?: Date) => void;
}

export default function (props: InputDateComponentProps) {
    const classes = useStyles();
    const {i18n, t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const {label, required} = props;
    const {
        views,
        onDateChange,
        timeViews,
        minDate,
        value,
        dateMomentFormat,
        startIcon,
        type,
        maxDate,
        format,
        endIcon,
        clearContent,
        ...inputProps
    } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    moment.locale(i18n.language);

    return (
        <div className={classes.root}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language}>
                {
                    type === 'date' &&
                    <DatePicker
                        label={label}
                        value={value || null}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(date: any) => {
                            onDateChange(date._d);
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                       fullWidth
                                       // InputProps={{
                                       //     ...params.InputProps,
                                       //     endAdornment: <InputAdornment position={'end'}>
                                       //         <Box color={'text.primary'}>
                                       //             <Calendar/>
                                       //         </Box>
                                       //     </InputAdornment>
                                       // }}
                                       helperText={props.helperText}
                                       error={props.error}/>}
                    />
                }
                {
                    type === 'time' &&
                    <TimePicker
                        label={label}
                        value={value || null}
                        onChange={(date: any) => {
                            onDateChange(date._d);
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                       fullWidth
                                       // InputProps={{
                                       //     ...params.InputProps,
                                       //     endAdornment: <InputAdornment position={'end'}>
                                       //         <Box color={'text.primary'}>
                                       //             <Clock/>
                                       //         </Box>
                                       //     </InputAdornment>
                                       // }}
                                       helperText={props.helperText}
                                       error={props.error}/>}
                    />
                }
                {
                    type === 'date_time' &&
                    <DateTimePicker
                        label={label}
                        value={value || null}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(date: any) => {
                            onDateChange(date._d);
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                error={props.error}
                                fullWidth
                                // InputProps={{
                                //     ...params.InputProps,
                                //     endAdornment: <InputAdornment position={'end'}>
                                //         <Box color={'text.primary'}>
                                //             <Calendar/>
                                //         </Box>
                                //     </InputAdornment>
                                // }}
                                helperText={props.helperText}
                            />}
                    />
                }
            </LocalizationProvider>
        </div>
    );
}
