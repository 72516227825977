import * as React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import {Router} from "react-router";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.Fragment>
      <Router history={history}>
          <App />
      </Router>
  </React.Fragment>
);
