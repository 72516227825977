import {Action} from 'redux';
import {ActivitySector, MissionType, Role} from 'store/types';
import {UPDATE_LOADING, User} from './AccountTypes';

export const LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_ERROR = 'LOAD_USERS_ERROR';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_REQUEST_ENDED = 'UPDATE_USER_REQUEST_ENDED';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_REQUEST_ENDED = 'ADD_USER_REQUEST_ENDED';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_USER = 'ADD_USER';
export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';


export const LOAD_USER_DETAIL_SUCCESS = 'LOAD_USER_DETAIL_SUCCESS';

export interface UsersRange {
  page: number;
  per_page?: number;
  keyword?: string;
  role_code?: string;
}

export interface LoadingUsersState {
  users: boolean;
}

export interface UserData {
  data: User[];
  total: number;
  pages: number;
  per_page: number;
  current_page: number;
}

export interface UsersState {
  loading: LoadingUsersState;
  data: UserData;
  range: UsersRange;
  user_detail?: User;
}
export interface AddUserRange{
  email?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  birthday?: string;
  role?: Role;
}
export interface BankAccount {
  iban?: string;
  paypal_email?: string;
}

export interface MissionRange {
  mission_types_uuid?: string[];
  user_uuid?: string;
}

export interface ActivitySectorRange {
  activity_sectors_uuid?: string[];
  user_uuid?: string;
}

export interface UserProfile {
  activity_sectors: ActivitySector[],
  mission_types: MissionType[],
  bank_account: BankAccount,
}

export interface LoadUsersRequest extends Action {
  type: typeof LOAD_USERS_REQUEST;
  range: UsersRange;
}

export interface LoadUsersSuccess extends Action {
  type: typeof LOAD_USERS_SUCCESS;
  data: UserData;
}

export interface UpdateLoading extends Action {
  type: typeof UPDATE_LOADING,
  state: boolean
}

export const DELETE_USER = 'DELETE_USER';

export interface DeleteUserAction extends Action {
  type: typeof DELETE_USER,
  data: DeleteUserActionParams
}

export interface DeleteUserActionParams {
  uuid: string
}


export interface LoadUsersError extends Action {
  type: typeof LOAD_USERS_ERROR;
}

export interface AddUserRequest extends Action {
  type: typeof ADD_USER_REQUEST;
}

export interface AddUserEnded extends Action {
  type: typeof ADD_USER_REQUEST_ENDED;
}

export interface UpdateUserRequest extends Action {
  type: typeof UPDATE_USER_REQUEST;
}

export interface UpdateUserRequestEnded extends Action {
  type: typeof UPDATE_USER_REQUEST_ENDED;
}


export interface AddUserAction extends Action {
  type: typeof ADD_USER,
  data: User
}


export interface UpdateUserAction extends Action {
  type: typeof UPDATE_USER,
  data: User
}

export interface LoadUserDetailAction extends Action {
  type: typeof LOAD_USER_DETAIL_SUCCESS;
  data: User;
}