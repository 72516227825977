import {GlobalDataState} from "../../store/types/GlobalDataType";


export const default_global_data: GlobalDataState  = {
  "roles": [
    {
      "uuid": "3304739e-27b6-4ac0-a5e3-138db00812c6",
      "title_i18n": {
        "en": "Administrator",
        "fr": "Administrateur"
      },
      "code": "administrator"
    },
    {
      "uuid": "75c11297-836a-41ea-bd91-eff0eac38d2c",
      "title_i18n": {
        "en": "Visitor",
        "fr": "Visiteur"
      },
      "code": "visitor"
    },
    {
      "uuid": "8671115f-b26f-448c-a953-e1045829ee2f",
      "title_i18n": {
        "en": "Teleoperator",
        "fr": "Télé-Opérateur"
      },
      "code": "teleoperator"
    }
  ],
  "status": [
    "UNVALIDATED",
    "ACTIVED",
    "UNACTIVED",
    "DELETED"
  ],
  "mission_types": [
    {
      "uuid": "f7066e5f-3712-4dec-98f3-7ee5464b85b0",
      "description_i18n": {
        "en": "During the appointment setting missions, you will have to contact companies or individuals in order to offer them an appointment for sales representatives to present them a product or a service.",
        "fr": "Lors des missions de prise de RDV, vous serez amenés à contacter des entreprises ou des particuliers afin de leur proposer un rendez-vous pour que des commerciaux leur présentent un produit ou un service."
      },
      "date_modified": "2022-05-06T08:46:00.145127+00:00",
      "title_i18n": {
        "en": "Make an appointment",
        "fr": "Prise de rendez-vous"
      },
      "date_added": "2022-05-06T08:46:00.145127+00:00"
    },
    {
      "uuid": "ce2b37b4-2a56-4d3a-9deb-f3d347902636",
      "description_i18n": {
        "en": "The qualification of files allows to obtain more reliable, more recent and/or more complete files. During these operations of qualification of files you will be brought to contact individuals or companies in order to collect various information: telephone, postal address, company name, first name, name, function, direct line, email...",
        "fr": "La qualification de fichier permet d’obtenir des fichiers plus fiables, plus récents et/ou plus complets. Lors de ces opérations de qualification de fichiers vous serez amenés à contacter des particuliers ou des entreprises afin de recueillir différentes informations: téléphone, adresse postale, raison sociale, prénom, nom, fonction, ligne directe, email…"
      },
      "date_modified": "2022-05-06T08:46:00.598686+00:00",
      "title_i18n": {
        "en": "File Qualifications",
        "fr": "Qualifications de fichiers"
      },
      "date_added": "2022-05-06T08:46:00.598686+00:00"
    },
    {
      "uuid": "f6b8bfcf-39eb-402c-9f6f-664af5e4a197",
      "description_i18n": {
        "en": "During the missions of creation of traffic, you will be brought to realize campaigns of communication which will have for goal to attract a maximum of prospects, active or inactive customers, on a point of sale or on a website thanks to offers or invitations which you will send by e-mail to the persons corresponding to the criteria which will be given to you",
        "fr": "Lors des missions de création de trafic, vous serez amenés à réaliser des campagnes de communication qui auront pour but d’attirer un maximum de prospects, clients actifs ou inactifs, sur un point de vente ou sur un site internet grâce à des offres ou des invitations que vous enverrez par mail auxpersonnes correspondant aux critères qui vous serons donnés."
      },
      "date_modified": "2022-05-06T08:46:01.174694+00:00",
      "title_i18n": {
        "en": "Traffic generation",
        "fr": "Création de trafic"
      },
      "date_added": "2022-05-06T08:46:01.174694+00:00"
    },
    {
      "uuid": "2540d0a8-ad3f-4b51-8894-f95bb6c3a2c8",
      "description_i18n": {
        "en": "During the missions of polls and telephone surveys, you will be asked to make telephone calls with the aim of finding out customers' expectations, measuring their satisfaction or identifying new markets.",
        "fr": "Lors des missions de sondages et enquêtes téléphoniques, vous serez amenés à réaliser des appels téléphoniques qui auront pour but de connaître les attentes des clients, mesurer leur satisfaction ou identifier de nouveaux marchés."
      },
      "date_modified": "2022-05-06T08:46:01.761442+00:00",
      "title_i18n": {
        "en": "Polls and surveys",
        "fr": "Sondages et enquêtes"
      },
      "date_added": "2022-05-06T08:46:01.761442+00:00"
    },
    {
      "uuid": "9342c586-c1fe-4347-b5f6-91d520f99d24",
      "description_i18n": {
        "en": "During project detection missions, you will be required to make telephone calls to target contacts likely to be interested in a commercial offer or to determine their purchasing intentions or their needs by asking them questions.",
        "fr": "Lors des missions de détection de projets, vous serez amenés à réaliser des appels téléphoniques qui auront pour but de cibler les contacts susceptibles d’être intéressés par une offre commerciale ou de déterminer leurs intentions d’achats ou leurs besoins en leur posant des questions."
      },
      "date_modified": "2022-05-06T08:46:02.362010+00:00",
      "title_i18n": {
        "en": "Project detection",
        "fr": "Détection de projet"
      },
      "date_added": "2022-05-06T08:46:02.362010+00:00"
    }
  ],
  "activity_sectors": [
    {
      "date_added": "2022-05-06T08:47:07.630866+00:00",
      "title_i18n": {
        "en": "Wellness and beauty",
        "fr": "Bien-être et beauté"
      },
      "date_modified": "2022-05-06T08:47:07.630866+00:00",
      "uuid": "0333d876-1f75-4028-af06-6956fc44aad4"
    },
    {
      "date_added": "2022-05-06T08:47:08.200189+00:00",
      "title_i18n": {
        "en": "Heating",
        "fr": "Chauffage"
      },
      "date_modified": "2022-05-06T08:47:08.200189+00:00",
      "uuid": "caf710db-bb36-4998-8676-2dc13ee4ad9e"
    },
    {
      "date_added": "2022-05-06T08:47:08.762041+00:00",
      "title_i18n": {
        "en": "Climatisation",
        "fr": "Climatisation"
      },
      "date_modified": "2022-05-06T08:47:08.762041+00:00",
      "uuid": "dfbbedd3-003b-41ff-b871-fba81408a991"
    },
    {
      "date_added": "2022-05-06T08:47:09.389858+00:00",
      "title_i18n": {
        "en": "Renewable energy",
        "fr": "Énergie renouvelable"
      },
      "date_modified": "2022-05-06T08:47:09.389858+00:00",
      "uuid": "026b1df5-703a-407f-8ac2-713d7b35fd27"
    },
    {
      "date_added": "2022-05-06T08:47:09.957835+00:00",
      "title_i18n": {
        "en": "Digital communication",
        "fr": "Communication digitale"
      },
      "date_modified": "2022-05-06T08:47:09.957835+00:00",
      "uuid": "e87ff2e3-e878-4110-a564-f3da3f06af43"
    }
  ],
  "civilities": [
    "MME",
    "MR"
  ]
}
