import overrides from './overrides';
import typography from "./typography";
import palette from "./palette";
import {PaletteMode, ThemeOptions} from "@mui/material";
import {createTheme, ThemeProvider, styled} from '@mui/material/styles';
import light_palette from "./light_palette";

const theme: (mode: PaletteMode) => ThemeOptions =
    (mode) => ({
        palette: mode == 'dark' ? palette : light_palette,
        //@ts-ignore
        typography,
        components: {
            ...overrides
        },
    })

const getTheme = (mode: PaletteMode) => createTheme(theme(mode));

export default getTheme;