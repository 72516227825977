import BaseService from './BaseService';
import { UserUrls } from './urls';
import { UsersRange, MissionRange, ActivitySectorRange, BankAccount, AddUserRange } from 'store/types/UserTypes';
import UsersServiceMock from 'services/mocks/UsersService.mock';

class AuthService {

  static list_users = (range: UsersRange) => {
    if ((window as any).mocked) {
      return UsersServiceMock.list_users();
    } else {
      return BaseService.getRequest(UserUrls.LIST_USERS(range), true);
    }
  };
  static get_user_detail = (user_uuid: string) => {
    return BaseService.getRequest(UserUrls.GET_USER_DETAIL(user_uuid), true);
  };
  static add_user = (range: AddUserRange) => {
    return BaseService.postRequest(UserUrls.ADD_USER, range, true);
  };
  static delete_user = (user_uuid: string) => {
    return BaseService.deleteRequest(UserUrls.DELETE_USER(user_uuid),{}, true);
  };


  static edit_user_mission = (range: MissionRange) => {
    return BaseService.putRequest(UserUrls.EDIT_MISSIONS, range, true);
  };
  static edit_user_bank_account = (range: BankAccount) => {
    return BaseService.putRequest(UserUrls.EDIT_BANK_ACCOUNT, range, true);
  };
  static edit_user_activity_sectors = (range: ActivitySectorRange) => {
    return BaseService.putRequest(UserUrls.EDIT_ACTIVITY_SECTORS, range, true);
  };


}

export default AuthService;
