import {
  ADD_USER,
  ADD_USER_REQUEST,
  ADD_USER_REQUEST_ENDED,
  AddUserAction,
  AddUserEnded,
  AddUserRequest,
  DELETE_USER,
  DeleteUserAction,
  DeleteUserActionParams,
  LOAD_USERS_ERROR,
  LOAD_USERS_REQUEST,
  LOAD_USERS_SUCCESS,
  LoadUsersError,
  LoadUsersRequest,
  LoadUsersSuccess,
  UPDATE_LOADING,
  UPDATE_USER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_REQUEST_ENDED,
  UpdateLoading,
  UpdateUserAction,
  UpdateUserRequest,
  UpdateUserRequestEnded,
  User,
  UserData,
  UsersRange,
  LoadUserDetailAction,
  LOAD_USER_DETAIL_SUCCESS
} from 'store/types';

export const loadUsersRequest = (range: UsersRange): LoadUsersRequest => ({
  type: LOAD_USERS_REQUEST,
  range
});

export const loadUsersSuccess = (data: UserData): LoadUsersSuccess => ({
  type: LOAD_USERS_SUCCESS,
  data
});

export const updateLoginLoading = (state: boolean): UpdateLoading => ({
  type: UPDATE_LOADING,
  state
});

export const loadUsersError = (): LoadUsersError => ({
  type: LOAD_USERS_ERROR
});

export const addUserRequest = (): AddUserRequest => ({
  type: ADD_USER_REQUEST
});


export const addUserAction = (data: User): AddUserAction => ({
  type: ADD_USER,
  data
});
export const updateUserAction = (data: User): UpdateUserAction => ({
  type: UPDATE_USER,
  data
});
export const addUserRequestEnded = (): AddUserEnded => ({
  type: ADD_USER_REQUEST_ENDED
});

export const updateUserRequest = (): UpdateUserRequest => ({
  type: UPDATE_USER_REQUEST
});
export const updateUserRequestEnded = (): UpdateUserRequestEnded => ({
  type: UPDATE_USER_REQUEST_ENDED
});
export const deleteUserAction = (data: DeleteUserActionParams): DeleteUserAction => ({
  type: DELETE_USER,
  data
});

export const loadUserDetailAction = (data: User): LoadUserDetailAction => ({
  type: LOAD_USER_DETAIL_SUCCESS,
  data
});
