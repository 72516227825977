export default {
    styleOverrides: {
        root: {
            '& svg': {
                height: 18,
                width: 18,
            }
        },
    }
};
