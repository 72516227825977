import { UsersRange } from "store/types/UserTypes";


// export const prefixer = 'http://localhost:8003/api/v1/';
export const prefixer = 'https://api.develop.test-callcenter.tk/api/v1/';


export const AuthUrls = {
  START_LOGIN: `${prefixer}auth/login/start`,
  END_LOGIN: `${prefixer}auth/login/code`,
  GET_CONNECTED_INFO: `${prefixer}auth/me`,
}

export const UserUrls = {
  UPDATE_PROFILE: `${prefixer}users/update`,
  LIST_USERS: (range: UsersRange) => `${prefixer}users?page=${range.page}&keyword=${range.keyword ?? ''}&per_page=20&role_code=${range.role_code ?? ''}`,
  GET_USER_DETAIL: (user_uuid: string) => `${prefixer}users/${user_uuid}`,
  DELETE_USER: (user_uuid: string) => `${prefixer}users/${user_uuid}`,
  ADD_USER: `${prefixer}users/new`,

  EDIT_BANK_ACCOUNT: `${prefixer}users/bank-account`,
  EDIT_MISSIONS: `${prefixer}users/update-mission`,
  EDIT_ACTIVITY_SECTORS: `${prefixer}users/update-activity-sectors`
};


export const GlobalUrl = {
  GET_GLOBAL_DATA: `${prefixer}users/default/data`,
};
