import React, {FunctionComponent, ReactNode} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from "react-i18next";
import {ButtonPrimary} from "../index";

interface DialogConfirmInterface {
  open: boolean;
  onConfirmDialogClose?: Function;
  text?: ReactNode | string;
  loading?: boolean;
  title?: string;
  onYesClick: Function;
}

const ConfirmationDialog: FunctionComponent<DialogConfirmInterface> = ({
  open,
  onConfirmDialogClose = () => {},
  text = '',
  loading = false,
  title = 'Confirmation',
  onYesClick = () => {}
}) => {
  const {t} = useTranslation();
  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      open={open}
      onClose={() => onConfirmDialogClose()}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color={'text.primary'}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonPrimary
            loading={loading}
            color={'primary'}
            onClick={() => onYesClick()}
            // sx={{ marginRight: 2 }}
            variant="contained">
          {t('dialog_action_confirm')}
        </ButtonPrimary>
        <Button
          onClick={() => onConfirmDialogClose()}
          variant="outlined"
          color={'secondary'}
          sx={{
              color: 'text.primary'
          }}
          disabled={loading}>
          {t('dialog_action_cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
