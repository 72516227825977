import {Action} from 'redux';
import {OptionsObject, SnackbarKey, SnackbarMessage} from "notistack";
import {Role} from "./AccountTypes";

export type SnackbarStatic = (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey

export const SET_GLOBAL_DATA = 'SET_GLOBAL_DATA';

export interface SetGlobalDataAction extends Action {
  type: typeof SET_GLOBAL_DATA,
  data: SetGlobalDataActionParams
}

export interface SetGlobalDataActionParams {
  data: GlobalDataState
}

export interface GlobalDataState {
  roles?: Role[];
  status?: string[];
  mission_types?: MissionType[];
  activity_sectors?: ActivitySector[];
  civilities?: string[];
}

export interface ActivitySector {
  date_added?: string;
  title_i18n?: I18N;
  date_modified?: string;
  uuid?: string;
  description_i18n?: I18N;
}

export interface MissionType {
  date_added?: string;
  title_i18n?: I18N;
  date_modified?: string;
  uuid?: string;
  description_i18n?: I18N;
}

export interface I18N {
  en?: string;
  fr?: string;
}
