import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LoginError,
  LoginRequest,
  LoginSuccess,
  Logout,
  LOGOUT,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  UpdatePasswordError,
  UpdatePasswordSuccess,
  User
} from 'store/types';

export const loginRequest = (): LoginRequest => ({
  type: LOGIN_REQUEST
});

export const loginSuccess = (
  user: User,
  token: string
): LoginSuccess => ({
  type: LOGIN_SUCCESS,
  user,
  token
});

export const loginError = (message: string): LoginError => ({
  type: LOGIN_FAILURE,
  message
});

export const updatePasswordSuccess = (
  message: string
): UpdatePasswordSuccess => ({
  type: UPDATE_PASSWORD_SUCCESS,
  message
});

export const updatePasswordError = (message: string): UpdatePasswordError => ({
  type: UPDATE_PASSWORD_FAILURE,
  message
});
export const logout = (): Logout => ({
  type: LOGOUT
});
