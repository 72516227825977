import React, {useState} from 'react';
import {NavLink as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import {Box, Button, Collapse, ListItem, Theme} from '@mui/material';
import {makeStyles} from "@mui/styles"

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {useTranslation} from 'react-i18next';
import {ChevronDown,  ChevronUp} from "react-feather";

interface NavItemProps {
    children?: React.ReactNode;
    className?: string;
    depth: number;
    href?: string;
    icon?: any;
    smallSize?: boolean;
    info?: any;
    open?: boolean;
    title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    item: {
        display: 'block',
        // paddingTop: 0,
        // paddingBottom: 0
    },
    itemLeaf: {
        display: 'flex',
        // paddingTop: 0,
        // paddingBottom: 0
    },
    button: {
        color: theme.palette.text.secondary,
        // padding: '10px 32px',
        justifyContent: 'flex-start',
        // textTransform: 'none',
        // letterSpacing: 0,
        width: '100%'
    },
    buttonLeaf: {
        // padding: '10px 8px',
        justifyContent: 'flex-start',
        // textTransform: 'none',
        // letterSpacing: 0,
        width: 'calc(100% - 12px)',
        margin: '0px 6px',
        boxShadow: 'none !important',
        color: theme.palette.text.secondary,
        '&:hover': {
            color: theme.palette.primary.main
        },
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium
            }
        }
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        color: 'inherit',
        marginRight: ({smallSize}: any) => smallSize ? 0 : theme.spacing(1),
    },
    title: {
        display: ({smallSize}: any) => smallSize ? 'none' : 'block',
        marginRight: 'auto'
    },
    active: {
        color: theme.palette.primary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium,
        },
        '& $icon': {
            color: theme.palette.primary.main,
        }
    },
    info: {}
}));

function NavItem(props: NavItemProps) {
    const {
        title,
        href,
        depth,
        children,
        icon: Icon,
        className,
        open: openProp = false,
        smallSize = false,
        info: Info,
        ...rest
    } = props;

    const classes = useStyles({smallSize});
    const [open, setOpen] = useState(openProp);
    const {t} = useTranslation();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    let paddingLeft = 18;


    const style = {paddingLeft};

    if (children) {
        return (
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                key={title}
                {...rest}>
                <Button className={classes.button} onClick={handleToggle}
                        style={style}
                >
                    {Icon && <Icon width={19} className={classes.icon}/>}
                    <div className={classes.title}>{t(title)}</div>
                    {open ? (
                        <ChevronUp/>
                    ) : (
                        <ChevronDown/>
                    )}
                </Button>
                <Collapse in={open}>
                    <Box sx={{ pl: 2 }}>
                        {children}
                    </Box>
                </Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={title}
            {...rest}>
            {
                //@ts-ignore
                <Button
                    activeClassName={classes.active}
                    className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                    component={RouterLink}
                    style={style}
                    to={href}>
                    {Icon ? (
                        <Icon width={19} className={classes.icon}/>
                    ) : (
                        <div/>
                        // <ReactSVG
                        //   src={Slack}
                        //   style={{ height: 20, width: 20, marginTop: 8 }}
                        //   className={classes.icon}
                        // />
                    )}
                    <div className={classes.title}>{t(title)}</div>
                    {Info && <Info className={classes.info}/>}
                </Button>
            }
        </ListItem>
    );
}

export default NavItem;
