export default {
    styleOverrides: {
        root: {
            borderRadius: 5,
            fontWeight: 500,
            minHeight: 48,
            boxShadow: 'none!important',
            padding: '12px 24px',
        },
        sizeSmall: {
            minHeight: 42,
            padding: '4px 6px',
        },
        // outlinedSecondary: {
        //     color: '#ffffff'
        // }
    }
};
