import BaseService from './BaseService';
import { GlobalUrl } from './urls';
import { get_global_data } from "../mocks/GlobalService.mock";

class GlobalService {

  static load_global_data = () => {
    if ((window as any).mocked) {
      return get_global_data();
    }
    else {
      return BaseService.getRequest(GlobalUrl.GET_GLOBAL_DATA, false);
    }
  };

}

export default GlobalService;
