import {ApplicationAction, GlobalDataState, SET_GLOBAL_DATA} from 'store/types';


const initialState: GlobalDataState = {
  activity_sectors: [],
  civilities: [],
  roles: [],
  status: [],
  mission_types: []
}


const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case SET_GLOBAL_DATA: {
      return action.data.data
    }

    default: {
      return state;
    }
  }
};

export default reducer;
