import {Users} from "react-feather";


export default [
  {
    subheader: '',
    items: [
      {
        title: 'navigation_items_user',
        href: '/app/users',
        icon: Users
      }
    ]
  },
];
