import {combineReducers, Reducer} from 'redux';
import GlobalDataReducer from './reducers/GlobalDataReducer';
import {ApplicationState} from './types';
import InternationalisationReducer from "./reducers/InternationalisationReducer";
import AccountReducer from "./reducers/AccountReducer";
import UsersReducer from './reducers/UsersReducer';

const rootReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  globaldata: GlobalDataReducer,
  users: UsersReducer,
  account: AccountReducer,
  i18n: InternationalisationReducer,
});

export default rootReducer;
