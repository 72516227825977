import {User} from 'store/types';

interface AuthResults {
  email: string;
  password: string;
  Authorization: string;
  message: string;
  data: User;
}

export const connected_user: User = {
  "uuid": "email|627b8728b38956e74a38ed02",
  "email": "dongmocarl+1@gmail.com",
  "firstname": "Carlos",
  "lastname": "Dongmo",
  "birthday": "2022-05-11",
  "status": "ACTIVED",
  "role": {
    "title_i18n": {
      "en": "Teleoperator",
      "fr": "Télé-Opérateur"
    },
    "code": "teleoperator",
    "uuid": "8671115f-b26f-448c-a953-e1045829ee2f"
  },
  "bank_account": undefined,
  "activity_sectors": [],
  "mission_types": [],
  "availabilities": [],
  "last_login": "2022-05-11T11:42:03.965648",
  "civility": "MME",
  "date_added": "2022-05-11T09:51:31.664833+00:00",
  "date_modified": "2022-05-11T11:37:50.271506+00:00"
};


export const auth_users: AuthResults[] = [
  {
    Authorization:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjE1ODQyNDEsImlhdCI6MTU5MDA0ODIzNiwic3ViIjoxfQ.Xvo-hJuQyz6I5-Co7Ezs3LFWUYjGmGTTlon1Bm7ANKk',
    message: 'Connexion reussi',
    email: 'admin@gmail.com',
    password: 'string',
    data: connected_user
  }
];

export const list_users: User[] = [];

export const connected_information = {};
