import React, {useState} from 'react';
import {NavBar, TopBar} from './components';
import {ErrorHandler} from 'components';
import styles from './Application.module.css';
import { Theme, useScrollTrigger} from "@mui/material";
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => ({
    topBar: {
        zIndex: 9999,
    }
}));

const Application = (props: any) => {
  const { children } = props;
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const classes = useStyles();
  const rootRef = React.createRef<HTMLDivElement>();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  console.log({trigger})

  return (
    <div className={styles.root}>
      <ErrorHandler>
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          onMobileOpen={() => setMobileNavOpen(true)}
          openMobile={isMobileNavOpen}
        />
      </ErrorHandler>
      <ErrorHandler>
        <TopBar rootRef={rootRef} className={classes.topBar} onMobileNavOpen={() => setMobileNavOpen(true)} />
      </ErrorHandler>
      <div className={styles.wrapper} ref={rootRef}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <ErrorHandler>{children}</ErrorHandler>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Application;
