import React, {useEffect, useState} from 'react';
import {
    Box,
    Divider,
    Drawer,
    Hidden, IconButton,
    List,
    ListSubheader, SwipeableDrawer, Theme,
} from '@mui/material';
import {makeStyles,} from "@mui/styles"
import {matchPath, useLocation} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {Logo} from 'components';
import navConfig from 'routes/navigationConfig';
import NavItem from './NavItem';
import {ChevronLeft} from "react-feather";

import clsx from "clsx";
import {useTranslation} from "react-i18next";


function renderNavItems(props: any) {
    const {
        items, ...rest
    } = props;
    return (
        <List disablePadding>
            {items.reduce(
                (acc: any, item: any) => reduceChildRoutes({acc, item, ...rest}),
                []
            )}
        </List>
    );
}

function reduceChildRoutes(props: any) {
    const {acc, pathname, item, smallSize = false, depth = 0} = props;
    const key = item.title + depth;

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                key={key}
                smallSize={smallSize}
                info={item.info}
                open={Boolean(open)}
                title={item.title}>
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    smallSize,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                smallSize={smallSize}
                key={key}
                info={item.info}
                title={item.title}
            />
        );
    }

    return acc;
}

//@ts-ignore
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        zIndex: 9999999
    },
    mobileDrawer: {
        width: 256,
        zIndex: 10000,
        backgroundColor: `${theme.palette.background.default} !important`
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        overflowX: 'hidden',
        transitionDuration: '500ms',
        transitionProperty: 'all',
        position: 'relative !important',
        backgroundColor: `${theme.palette.background.default} !important`,
        height: 'calc(100%)',
        '& .ps__rail-x': {
            display: 'none!important'
        },
        '& .MuiButton-label svg': {
            position: 'relative',
            zIndex: 2,
            transitionDuration: '350ms',
            transitionProperty: 'all',
        },
        '& .MuiButton-label div': {
            whiteSpace: 'nowrap',
            position: 'relative',
            zIndex: 1,
            width: 0,
            transitionDuration: '350ms',
            transitionProperty: 'all',
        }
    },
    desktopDrawerAfter: {
        width: 80,
        '& .MuiButtonBase-root': {
            maxWidth: 64
        },
        '& .scrollbar-container': {
            width: 90
        },
        '& .MuiButton-label div': {
            whiteSpace: 'nowrap',
            transform: 'translateX(-40px)',
            opacity: 0
        },
        '& .MuiButton-label svg': {
            transform: 'translateX(4px)',
        }
    },
    desktopToggleSize: {
        position: 'absolute',
        bottom: '24px',
        zIndex: 2,
        transitionDuration: '500ms',
        transitionProperty: 'all',
        border: `1px solid ${theme.palette.primary.contrastText}`,
        padding: 4,
        right: '24px !important'
    },
    desktopToggleSizeAfter: {
        transform: "rotate(180deg)"
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    }
}));

//@ts-ignore
function NavBar({openMobile, onMobileClose, onMobileOpen}) {
    const classes = useStyles();
    const location = useLocation();
    const {t} = useTranslation();
    const [smallSize, setSmallSize] = useState(false);
    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar>
                <Hidden lgUp>
                    <Box p={2} display="flex" justifyContent="center">
                        <RouterLink to="/">
                            <Logo/>
                        </RouterLink>
                    </Box>
                </Hidden>
                <Divider/>
                <Box py={2}>
                    {navConfig.map((config) => (
                        <List
                            key={config.subheader}
                            subheader={
                                <Box px={3}>
                                    <ListSubheader disableGutters disableSticky>
                                        {t(config.subheader)}
                                    </ListSubheader>
                                </Box>
                            }>
                            {renderNavItems({
                                items: config.items,
                                pathname: location.pathname,
                                smallSize
                            })}
                        </List>
                    ))}
                </Box>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <SwipeableDrawer
            anchor="left"
            classes={{root: classes.root, paper: classes.mobileDrawer}}
            onClose={onMobileClose}
            onOpen={onMobileOpen}
            open={openMobile}
            variant="temporary">
            {content}
        </SwipeableDrawer>
    );
}

export default NavBar;
