import BaseService from './BaseService';
import {AuthUrls} from './urls';
import {login_mock} from "../mocks";
import {string} from 'yup/lib/locale';

class AuthService {

  static get_userInfo = () => {
    return BaseService.postRequest(AuthUrls.GET_CONNECTED_INFO, {}, true);
  };

  static startLogin = (email: string) => BaseService.postRequest(AuthUrls.START_LOGIN, email, false);

  static endLogin = (info: object) => BaseService.postRequest(AuthUrls.END_LOGIN, info, false)
}

export default AuthService;
