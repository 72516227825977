/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {
  AppBar,
  Box,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  useScrollTrigger
} from '@mui/material';
import Account from './Account';
import {useHistory} from 'react-router';
import {Bell, Check, Menu as MenuIcon, Moon, Sun} from 'react-feather'
import {useTranslation} from "react-i18next";
import SecureLS from "secure-ls";
import {makeStyles, useTheme} from "@mui/styles";
import {ColorModeContext} from 'App';

const localStorage = new SecureLS({encodingType: 'aes'});


interface TopBarProps {
  onMobileNavOpen: any;
  className?: string;
  rootRef: any
}


const useStyles = makeStyles((theme: Theme) => ({
  notficationReadAll: {
    color: 'green',
    fontSize: '14px',
    marginLeft: '10px',
    cursor: 'pointer'
  },
  readButton: {
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    background: 'green',
    marginRight: '10px'
  },
  onreadButton: {
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    background: 'red',
    marginRight: '10px'
  },
  icon: {
    '& svg': {
      height: 24,
      width: 24
    }
  }
}));

function ElevationScroll(props: any) {
  const {children, window} = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const TopBar = (props: TopBarProps) => {
  const {onMobileNavOpen, className, rootRef, ...rest} = props;
  const {i18n} = useTranslation();
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const router = useRouter();
  const history = useHistory();
  const classes = useStyles();
  const theme: Theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  const [anchorNotification, setAnchorNotification] = React.useState(null);
  const [active, setActive] = useState(false)

  const handleClick = (value: string) => (event: any) => {
    if (value == 'language') {
      setAnchorEl(event.currentTarget);
    } else if (value == 'notification') {
      setAnchorNotification(event.currentTarget)
    } else if (value == 'setting') {

      history.push('/app/user/notification');
    }
  };
  const readAllClick = () => {
    if (active) {
      setActive(false)
    } else {
      setActive(true)
    }
  }

  React.useEffect(() => {
    i18n.changeLanguage(localStorage.get('language') || 'fr');
  }, [i18n]);

  const handleClose = (lng: string | undefined) => {
    if (!!lng) {
      i18n.changeLanguage(lng.toLowerCase());
      localStorage.set('language', lng.toLowerCase());
    }
    setAnchorEl(null);
    setAnchorNotification(null)
  };

  return (
    <>
      <ElevationScroll>
        <AppBar>
          <Toolbar>
            <IconButton
              classes={{root: classes.icon}}
              size={"large"}
              color="inherit"
              onClick={onMobileNavOpen}>
              <MenuIcon size={24}/>
            </IconButton>

            <Hidden mdDown>
              {/* <RouterLink to="/">
                <Logo />
              </RouterLink> */}
            </Hidden>
            <Box ml={2} flexGrow={1}/>
            {/* <IconButton
              classes={{root: classes.icon}}
              color={'inherit'}
              size={'small'}
              onClick={handleClick('language')}
            >{i18n.language}</IconButton> */}

            <Box>
              <IconButton
                classes={{root: classes.icon}}
                color={'inherit'}
                size={'small'}
                onClick={handleClick('notification')}
              >
                <Bell/>
              </IconButton>
            </Box>

            <Box /* ml={2} */>
              <IconButton
                classes={{root: classes.icon}}
                color={'inherit'}
                size={'small'}
                onClick={colorMode.toggleColorMode}
              >
                {theme.palette.mode === 'dark' ? <Sun/> : <Moon/>}
              </IconButton>
            </Box>

            <Box /* ml={2} */>
              <Account/>
            </Box>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => handleClose(undefined)}>
              {[
                {value: 'fr', title: 'Français'},
                {value: 'en', title: 'English'}
              ].map((i) => (
                <MenuItem onClick={() => handleClose(i.value)} key={i.value}>
                  {i.title}
                </MenuItem>
              ))}
            </Menu>
            <Menu
              id="simple-menu"
              anchorEl={anchorNotification}
              keepMounted
              open={Boolean(anchorNotification)}
              onClose={() => handleClose(undefined)}>
              <Grid container spacing={2} justifyContent={'space-between'} alignItems={'flex-end'}>
                <Grid item>
                  <Box ml={2}>
                    <h4>
                      Notification(s)
                    </h4>
                    <Divider></Divider>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mr={2}>
                    <span>
                      <Check/>
                    </span>
                    <span onClick={readAllClick}
                          className={classes.notficationReadAll}>{t('page_notifications_read_all')}</span>
                  </Box>
                </Grid>
              </Grid>

              {[
                {label: 'Create account', description: 'l\'utilisateur ramses vient de créer un compte'},
                {label: 'Create groupe', description: 'l\'utilisateur ramses vient de créer un groupe nommé admin'},
                {
                  label: 'Modify role of groupe',
                  description: 'l\'utilisateur ramses vient de modifier les droit du groupe admin'
                },
              ].map((i) => (
                <MenuItem onClick={() => handleClose(i.label)} key={i.label}>
                  <span className={active ? classes.onreadButton : classes.readButton}></span> {i.description}
                </MenuItem>
              ))}

            </Menu>
            <Divider></Divider>

          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
};

export default TopBar;
