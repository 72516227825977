import React, {useRef} from 'react';
import {Provider} from 'react-redux';
import {
    createStyles,
    jssPreset,
    makeStyles,
    StylesProvider,
} from "@mui/styles";
import {create} from 'jss';
// import rtl from 'jss-rtl';
import CssBaseline from '@mui/material/CssBaseline';
import {SnackbarProvider} from 'notistack';
import store from 'store';
import Routes from 'routes';
import {createTheme, ThemeProvider, styled} from '@mui/material/styles';

import 'translations';
// import { ScrollReset } from 'components';
import Auth from 'routes/Auth';
import config from "./config";
import MockedButton from "./components/MockedButton";
import {StyledEngineProvider} from "@mui/styled-engine-sc";
import {IconButton, PaletteMode, Theme} from "@mui/material";
import getTheme from "./theme";
import SecureLS from 'secure-ls';
import {X} from "react-feather";

const ls = new SecureLS({ encodingType: 'aes' });

// import 'styles/index.scss';

const jss = create({plugins: [...jssPreset().plugins,]});

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    }
});

const App = () => {

    let initialMode = ls.get('palette_mode') || 'light';

    const notistackRef = useRef<any>();

    const [mode, setMode] = React.useState<PaletteMode>(initialMode);
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {

                setMode((prevMode) => {
                    const newMode = (prevMode === 'light' ? 'dark' : 'light');
                    ls.set('palette_mode', newMode);
                    return newMode;
                });
            },
        }),
        [],
    );

    const theme: any = React.useMemo(
        () => getTheme(mode),
        [mode],
    );

    return (
        <SnackbarProvider
            maxSnack={5}
            ref={notistackRef}
            action={(key) => (
                <IconButton
                    onClick={() => notistackRef.current.closeSnackbar(key)}
                >
                    <X height={'18px'} width={'18px'} color={'#ffffff'} />
                </IconButton>
            )}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
            <StyledEngineProvider injectFirst>
                <Provider store={store}>
                    <ColorModeContext.Provider value={colorMode}>
                        <ThemeProvider theme={theme}>
                            <StylesProvider jss={jss}>
                                <React.Fragment>
                                    <CssBaseline/>
                                    <Auth>
                                        {/* <ScrollReset /> */}
                                        <Routes/>
                                        {/*{config.env === 'DEV' && <MockedButton/>}*/}
                                    </Auth>
                                </React.Fragment>
                            </StylesProvider>
                        </ThemeProvider>
                    </ColorModeContext.Provider>
                </Provider>
            </StyledEngineProvider>
        </SnackbarProvider>
    );
};

export default App;
