export default {
    styleOverrides: {
        root: {
            minHeight: 38,
            minWidth: 38,
            '& svg': {
                height: 16,
                width: 16
            },
        },
        colorPrimary: {
            backgroundColor: 'rgba(255,136,0,0.1)',
            '&:hover': {
                backgroundColor: 'rgba(255,136,0,0.3)',
            }
        },
        colorSecondary: {
            backgroundColor: 'rgba(255,72,66,0.1)',
            '& svg': {
              color: 'rgba(255,72,66, 1)'
            },
            '&:hover': {
                backgroundColor: 'rgba(255,72,66,0.3)',
            }
        }
    }
};
