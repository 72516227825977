import React, {useRef, useState} from 'react';
import {useHistory,} from "react-router-dom";
import {useSnackbar} from 'notistack';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Typography
} from '@mui/material';
import {useTranslation} from "react-i18next";
import {logout} from 'store/actions';
import {makeStyles, useTheme,} from "@mui/styles"
import {useDispatch, useSelector} from 'react-redux';
import {ApplicationState} from "../../../../store/types";
import {LogOut, Settings, User} from "react-feather";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 50,
    width: 50,
    marginRight: theme.spacing(1)
  },
  avatarBig: {
    height: "70px !important",
    width: "70px !important",
    margin: "0px !important"
  },
  popover: {
    width: 200
  }
}));

function Account() {
  const classes = useStyles();
  const ref = useRef(null);
  const {enqueueSnackbar} = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const {t} = useTranslation();
  const theme: Theme = useTheme();
  const color = theme.palette.text.primary
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    user
  } = useSelector((state: ApplicationState) => state.account);

  const [openDialog, setOpenDialog] = useState(false);

  const handleProfil = async () => {
    history.push('/app/profile');
    handleClose();
  }

  const handleSettings = async () => {
    history.push('/app/settings');
    handleClose();
  }

  const handleLogout = async () => {
    try {
      handleCloseDialog();
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar(t('unable_to_disconnect'), {
        variant: 'error'
      });
    }
  };

  const handleOpenDialog = () => {
    handleClose();
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  return (
    <>
      {
        openDialog &&
          <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              maxWidth={'xs'}
              style={{borderRadius: 15}}
          >
              <Box
                  m={2}
                  height={'calc(100% - 70px)'}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}>
                  <Box>
                      <Typography variant={"h4"} align={'center'}>
                          Alors c'est un au revoir ?
                      </Typography>
                  </Box>
                  <Box
                      my={2}
                      height={142}
                      style={{
                        backgroundImage: 'url(/gif/byebye.gif)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: 5
                      }}
                      width={188}
                      mx={'auto'}
                  />
                  <Box>
                      <Box m={1}>
                          <Button
                              variant={"outlined"}
                              fullWidth
                              onClick={handleLogout}>
                              Oui... à plus tard
                          </Button>
                      </Box>
                      <Box m={1}>
                          <Button
                              variant={"contained"}
                              fullWidth
                              onClick={handleCloseDialog}
                          >
                              Non non je reste
                          </Button>
                      </Box>
                  </Box>
              </Box>
          </Dialog>
      }

      <IconButton
        size={'large'}
        color={'inherit'}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}>
        <Avatar src={"https://lh3.googleusercontent.com/a-/AOh14Gg81cStZ7QrpGiWtSg5YkrqISQtvr8ReHCKNUIyHQM=s192-c-mo"}
                alt={`${user?.firstname || ''}  ${user?.lastname || ''}`} className={classes.avatar}/>
      </IconButton>
      <Menu
        onClose={handleClose}

        keepMounted
        // PaperProps={{className: classes.popover}}
        PaperProps={{
          className: classes.popover,
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        anchorEl={ref.current}
        open={isOpen}>

        <Box sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 2
        }}>
          <Box sx={{
            borderColor: 'secondary.main',
            border: 2,
            borderStyle: 'solide',
            padding: "4px",
            width: 80,
            height: 80,
            display: "flex",
            justifyContent: "center",
            borderRadius: 72,
            alignItems: "center",
            flexDirection: "column",
          }}>
            <Avatar sx={{margin: 0}}
                    src={"https://lh3.googleusercontent.com/a-/AOh14Gg81cStZ7QrpGiWtSg5YkrqISQtvr8ReHCKNUIyHQM=s192-c-mo"}
                    alt={`${user?.firstname || ''}  ${user?.lastname || ''}`} className={classes.avatarBig}/>
          </Box>
          <Typography sx={{mt: 2, mb: 2}} color="black">{`${user?.firstname || 'Firstname'}`}</Typography>
        </Box>
        <Divider sx={{mb: 2}}/>
        <MenuItem onClick={handleProfil}>
          <ListItemIcon> <User color={color}/> </ListItemIcon>
          <ListItemText>{t('topbar_profile_profil')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleSettings}>
          <ListItemIcon><Settings color={color}/></ListItemIcon>
          <ListItemText>Paramètres</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenDialog}>
          <ListItemIcon> <LogOut color={color}/> </ListItemIcon>
          <ListItemText>{t('topbar_profile_logout')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
